import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserLayout from "./Layouts/UserLayout";
import Home from "./Screens/Users/Home";
import "./App.css";
import "./Styles.css";
import "./scss/style.scss";
import "./Message.scss";
import About from "./Screens/Users/About";
import RegisterBusiness from "./Screens/Users/RegisterBusiness";
import SingleServicePage from "./Screens/Users/SingleServicePage";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import BookingConfirmation from "./Screens/Users/BookingConfirmation";
import LiveChatWith from "./Screens/Users/LiveChatWith";
import ParticularSearchPage from "./Screens/Users/ParticularSearchPage";
import {userContext} from './Context/UserContext'
import Loader from "./Components/Loader";
import Privacy from "./Screens/Users/Privacy";
import Terms from "./Screens/Users/Terms";
import "react-datepicker/dist/react-datepicker.css";
import VendorProfile from "./Screens/VendorProfile";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));


export default function App() {
  const {load} = React.useContext(userContext)
    return (
      <>
        {load && <Loader />}
        <ToastContainer />
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <UserLayout custom_class="fixed-top">
                    <Home />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/privacy-policy-mobile"
                render={() => (
                  <Privacy />
                )}
              />
              <Route
                exact
                path="/terms-condition-mobile"
                render={() => (
                  <Terms />
                )}
              />
              <Route
                exact
                path="/about"
                render={() => (
                  <UserLayout custom_class="active">
                    <About />
                  </UserLayout>
                )}
              />
               <Route
                exact
                path="/particular-serach-page"
                render={() => (
                  <UserLayout custom_class="active">
                    <ParticularSearchPage />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/register_business"
                render={() => (
                  <UserLayout custom_class="active">
                    <RegisterBusiness />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/privacy-policy"
                render={() => (
                  <UserLayout custom_class="active">
                    <Privacy />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/terms-conditions"
                render={() => (
                  <UserLayout custom_class="active">
                    <Terms />
                  </UserLayout>
                )}
              />
              {/* <Route
                exact
                path="/single_service_page"
                render={() => (
                  <UserLayout custom_class="active">
                    <SingleServicePage />
                  </UserLayout>
                )}
              /> */}
              <Route
                exact
                path="/single_service_page"
                render={() => (
                  <UserLayout custom_class="active">
                    <VendorProfile />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/booking_confirmation"
                render={() => (
                  <UserLayout custom_class="active">
                    <BookingConfirmation />
                  </UserLayout>
                )}
              />
              <Route
                exact
                path="/live_chat_with"
                render={() => (
                  <UserLayout custom_class="active">
                    <LiveChatWith />
                  </UserLayout>
                )}
              />


              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </>
    );
}
